import { PayloadAction, createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import {
	ConfigurationKey,
	IConfiguration,
	IRowOpeningHour,
	IState,
	LocationFIeld,
	OpeningDaily,
	OpeningsHour,
} from 'types/LocationInformation';
import {
	getAvailableConfigThunk,
	getLocationBrandsThunk,
	getLocationInfoThunk,
	getLocationLanguagesThunk,
	getLocationProductsThunk,
	getLocationServicesThunk,
	saveLocationInfoThunk,
} from './locationInformationThunk';

import { nanoid } from 'nanoid';

export const normalizeOpeningHourFe = (rawOpeningHour: IRowOpeningHour[]) => {
	const newOpeningHour: OpeningsHour[] = [];
	rawOpeningHour.forEach((elm: IRowOpeningHour) => {
		const matchingHours = newOpeningHour.find(
			newElm => JSON.stringify(newElm.openings) === JSON.stringify(elm.openings),
		);
		if (matchingHours) {
			matchingHours.days = [...matchingHours.days, elm.weekDay - 1];
		} else {
			newOpeningHour.push({
				key: nanoid(),
				days: [elm.weekDay - 1],
				openings: elm.openings,
			});
		}
	});
	newOpeningHour.forEach(elm =>
		elm.openings.forEach(
			(openingData: OpeningDaily) => (openingData.key = nanoid()),
		),
	);

	return newOpeningHour || [];
};

const numberOnlyRegex = /^[0-9\s]+$/;

const initialState: IState = {
	locationName: '',
	searchField: {
		brands: '',
		languages: '',
		products: '',
		services: '',
	},

	locationFields: {
		address1: '',
		phoneNumber: '',
		websiteUrl: '',
		email: '',
		country: '',
	},
	currentConfiguration: {
		languages: [],
		services: [],
		brands: [],
		products: [],
	},
	availableConfiguration: {
		languages: [],
		services: [],
		brands: [],
		products: [],
	},
	openingsHour: [
		{
			key: nanoid(),
			days: [],
			openings: [{ key: nanoid(), open: undefined, close: undefined }],
		},
	],

	errorCode: 0,
	errorMessage: '',
};

export const getAvailableConfig = createAsyncThunk(
	'localInformation/getAvailableConfig',
	getAvailableConfigThunk,
);
export const getLocationInfo = createAsyncThunk(
	'localInformation/getLocationInfo',
	getLocationInfoThunk,
);
export const saveLocationInfo = createAsyncThunk(
	'localInformation/saveLocationInfo',
	saveLocationInfoThunk,
);
export const getLocationLanguages = createAsyncThunk(
	'localInformation/getLocationLanguages',
	getLocationLanguagesThunk,
);
export const getLocationProducts = createAsyncThunk(
	'localInformation/getLocationProducts',
	getLocationProductsThunk,
);
export const getLocationServices = createAsyncThunk(
	'localInformation/getLocationServices',
	getLocationServicesThunk,
);
export const getLocationBrands = createAsyncThunk(
	'localInformation/getLocationBrands',
	getLocationBrandsThunk,
);

const locationInformationSlice = createSlice({
	name: 'locationsInformation',
	initialState,
	reducers: {
		setLocationFields: (
			state,
			{ payload }: PayloadAction<{ text: string; fieldName: LocationFIeld }>,
		) => {
			const { fieldName, text } = payload;
			if (fieldName) {
				if (fieldName === 'phoneNumber') {
					if (numberOnlyRegex.test(text) || text === '') {
						state.locationFields[fieldName] = text;
					}
				} else {
					state.locationFields[fieldName] = text;
				}
			}
		},
		setSearchField: (
			state,
			{
				payload,
			}: PayloadAction<{
				text: string;
				fieldKey: ConfigurationKey;
			}>,
		) => {
			const { fieldKey, text } = payload;
			state.searchField[fieldKey] = text;
		},
		resetSearchField: (state, { payload }: PayloadAction<ConfigurationKey>) => {
			state.searchField[payload] = '';
		},
		resetState: state => {
			state.searchField = {
				brands: '',
				languages: '',
				products: '',
				services: '',
			};
		},
		setHour: (
			state,
			{
				payload,
			}: PayloadAction<{
				hour: number;
				dataIndex: number;
				daysIndex: number;
				target: 'open' | 'close';
			}>,
		) => {
			const { hour, dataIndex, daysIndex, target } = payload;
			state.openingsHour[dataIndex].openings[daysIndex][target] = hour;
		},
		setDay: (
			state,
			{
				payload,
			}: PayloadAction<{
				days: number[];
				dataIndex: number;
			}>,
		) => {
			const { days, dataIndex } = payload;
			state.openingsHour[dataIndex].days = days.sort();
		},
		addOpeningConfigRow: state => {
			state.openingsHour.push({
				key: nanoid(),
				days: [],
				openings: [{ key: nanoid(), open: undefined, close: undefined }],
			});
		},
		addOpeningHourRow: (
			state,
			{
				payload,
			}: PayloadAction<{
				dataIndex: number;
			}>,
		) => {
			state.openingsHour[payload.dataIndex].openings.push({
				close: undefined,
				open: undefined,
				key: nanoid(),
			});
		},
		deleteOpeningConfigRow: (
			state,
			{
				payload,
			}: PayloadAction<{
				dataIndex: number;
				daysIndex: number;
			}>,
		) => {
			const { daysIndex, dataIndex } = payload;
			if (state.openingsHour.length > 1) {
				if (state.openingsHour[dataIndex].openings.length === 1) {
					state.openingsHour.splice(dataIndex, 1);
				} else {
					state.openingsHour[dataIndex].openings.splice(daysIndex, 1);
				}
			} else if (state.openingsHour[dataIndex].openings.length > 1) {
				state.openingsHour[dataIndex].openings.splice(daysIndex, 1);
			} else {
				state.openingsHour[dataIndex].days = [];
				state.openingsHour[dataIndex].openings[0].close = undefined;
				state.openingsHour[dataIndex].openings[0].open = undefined;
			}
		},
		handleSelectAll: (
			state,
			{
				payload,
			}: PayloadAction<{
				configurationKey: ConfigurationKey;
			}>,
		) => {
			const isSelectAll =
				state.currentConfiguration[payload.configurationKey].length ===
				state.availableConfiguration[payload.configurationKey].length;
			if (isSelectAll) {
				state.currentConfiguration[payload.configurationKey] = [];
			} else {
				state.currentConfiguration[payload.configurationKey] =
					state.availableConfiguration[payload.configurationKey];
			}
		},
		handleClickItem: (
			state,
			{
				payload,
			}: PayloadAction<{
				configurationItem: IConfiguration;
				configurationKey: ConfigurationKey;
			}>,
		) => {
			const isSelectedIndex = state.currentConfiguration[payload.configurationKey]
				.map(el => el.code)
				.indexOf(payload.configurationItem.code);

			const isSelected =
				state.currentConfiguration[payload.configurationKey]
					.map(el => el.code)
					.indexOf(payload.configurationItem.code) > -1;

			if (isSelected) {
				state.currentConfiguration[payload.configurationKey] =
					state.currentConfiguration[payload.configurationKey].filter(
						(_, idx) => idx !== isSelectedIndex,
					);
			} else {
				state.currentConfiguration[payload.configurationKey] = [
					...state.currentConfiguration[payload.configurationKey],
					payload.configurationItem,
				];
			}
		},
	},
	extraReducers: builder => {
		builder
			.addCase(getLocationLanguages.fulfilled, (state, { payload }) => {
				state.availableConfiguration.languages = payload;
			})
			.addCase(getLocationProducts.fulfilled, (state, { payload }) => {
				state.availableConfiguration.products = payload;
			})
			.addCase(getLocationServices.fulfilled, (state, { payload }) => {
				state.availableConfiguration.services = payload;
			})
			.addCase(getLocationBrands.fulfilled, (state, { payload }) => {
				state.availableConfiguration.brands = payload;
			})
			.addCase(getLocationInfo.fulfilled, (state, { payload }) => {
				const {
					address1,
					currentBrands,
					currentLanguages,
					currentProducts,
					currentServices,
					openingsHour,
					phoneNumber,
					websiteUrl,
					email,
					country,
					providerLocationName,
				} = payload;
				if (openingsHour) {
					const newOpeningHour = normalizeOpeningHourFe(openingsHour);
					if (newOpeningHour) {
						state.openingsHour = newOpeningHour;
					}
				}
				state.locationFields = state.locationFields = {
					address1: address1.trim(),
					phoneNumber: phoneNumber.trim(),
					websiteUrl,
					email,
					country,
				};
				state.currentConfiguration = {
					languages: currentLanguages,
					products: currentProducts,
					services: currentServices,
					brands: currentBrands,
				};
				state.locationName = providerLocationName;
			})
			.addCase(saveLocationInfo.fulfilled, state => {
				state.searchField = {
					brands: '',
					languages: '',
					products: '',
					services: '',
				};
			});
	},
});

export const {
	setLocationFields,
	setSearchField,
	resetSearchField,
	resetState,
	setHour,
	setDay,
	addOpeningConfigRow,
	addOpeningHourRow,
	deleteOpeningConfigRow,
	handleSelectAll,
	handleClickItem,
} = locationInformationSlice.actions;
export default locationInformationSlice.reducer;
