import { changePage, getPaymentsHistory } from 'feature/payments/paymentsSlice';
import { Fragment, useEffect, useMemo } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { setCollection } from 'feature/form/formSlice';
import useDevice from 'hooks/DesktopDevice.hook';
import { AppDispatch, RootState } from 'store/store';
import BoxOverflow from 'styles/box/BoxOverflow';
import { Collections } from 'types/Form';
import PaymentsFooter from './PaymentsFooter.component';
import PaymentsHeader from './PaymentsHeader.component';
import PaymentsList from './PaymentsList.component';

const ViewPayments = () => {
	const { isDesktop } = useDevice();
	const dispatch = useDispatch<AppDispatch>();
	const { page } = useSelector((store: RootState) => store.payments);

	const {
		dateOfService,
		inputPaymentRunId,
		inputTotalAmount,
		sortingFields,
		sortingDirections,
		collection,
	} = useSelector((store: RootState) => store.form);

	useEffect(() => {
		dispatch(setCollection(Collections.PAYMENTS));
	}, [dispatch]);

	const searchParams = useMemo(
		() => ({
			inputPaymentRunId,
			inputTotalAmount,
			dateOfService,
			sortingDirections,
			sortingFields,
		}),
		[
			inputPaymentRunId,
			inputTotalAmount,
			dateOfService,
			sortingDirections,
			sortingFields,
		],
	);

	useEffect(() => {
		if (searchParams && collection === Collections.PAYMENTS && page)
			dispatch(
				getPaymentsHistory({
					showloader: true,
					limit: '8',
				}),
			);
	}, [dispatch, isDesktop, page, searchParams, collection]);

	useEffect(() => {
		if (searchParams) dispatch(changePage(1));
	}, [dispatch, searchParams]);

	return (
		<Fragment>
			<BoxOverflow
				mtScrollbar="76px"
				variant="xy"
				sx={{ height: !!isDesktop ? 720 : 490 }}
			>
				<PaymentsHeader />
				<PaymentsList />
			</BoxOverflow>
			<PaymentsFooter />
		</Fragment>
	);
};

export default ViewPayments;
