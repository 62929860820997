import { setOtherServices } from 'feature/claims/claimSlice';
import { Dispatch, FC, SetStateAction, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { AppDispatch, RootState } from 'store/store';
import { TableRow, Typography } from 'styles';
import { IClaim, IClaimComponents } from 'types/Claim';
import { formatInputNumberValue } from 'utils/utils';

import palette from 'styles/theme/Palette';
import { useTranslate } from 'stylesHooks';
import { TRANSLATION_KEYS } from 'utils/constants';
import { OutlinedInput, TableCell } from './PriceEstimate';

const StandardItemSection: FC<{
	editMode: boolean;
	isError: boolean;
	tempAmounts: IClaim['amount'];
	setTempAmounts: Dispatch<SetStateAction<IClaim['amount']>>;
	checkIsError: (row: IClaimComponents) => boolean;
	handleChangeAmounts: (
		formattedValue: string,
		serviceId: number | null,
		key: string,
	) => void;
	isOtherComponent: (row: IClaimComponents) => boolean;
	addCurrency: (value: number | string | undefined) => string;
	status: 'beforeCalculation' | 'afterCalculation' | 'onEdit';
	showGross?: boolean;
}> = ({
	editMode,
	isError,
	tempAmounts,
	setTempAmounts,
	checkIsError,
	handleChangeAmounts,
	isOtherComponent,
	addCurrency,
	status,
	showGross,
}) => {
	const { t: tClientLabels } = useTranslate(TRANSLATION_KEYS.CLIENT_LABELS);
	const dispatch = useDispatch<AppDispatch>();

	const { member } = useSelector((state: RootState) => state.member);

	useEffect(() => {
		const otherServices =
			member?.benefit?.others.map(el => ({
				serviceLabel: el.serviceLabel,
				serviceId: el.serviceId,
			})) || [];

		dispatch(setOtherServices(otherServices));
	}, [dispatch, member]);

	return (
		<>
			{tempAmounts.components
				.filter(el => !isOtherComponent(el))
				.map(row => (
					<TableRow
						key={row.serviceId}
						sx={{
							height: { sm: 56, lg: 70 },
							backgroundColor: palette.white.main,
							outline: `2px solid ${palette.grey6.main}`,
						}}
					>
						<TableCell sx={{ width: { sm: 136, lg: 230 } }}>
							<Typography variant="body" sx={{ pl: '10px' }}>
								{tClientLabels(serviceIdToLable(row.serviceId!))}
							</Typography>
						</TableCell>
						{showGross ? (
							<>
								<TableCell align="center">
									{['beforeCalculation', 'onEdit'].includes(status) ? (
										<OutlinedInput
											error={checkIsError(row)}
											isCurrency={true}
											value={formatInputNumberValue(row.grossRetail || '')}
											handleInputNumberChange={formattedValue =>
												handleChangeAmounts(formattedValue, row.serviceId, 'grossRetail')
											}
										/>
									) : (
										<Typography variant="value1">
											{addCurrency(row.grossRetail)}
										</Typography>
									)}
								</TableCell>
								<TableCell align="center" $smallIcon></TableCell>
								<TableCell align="center">
									<Typography variant="value1">
										{addCurrency(row.inStorePromotion)}
									</Typography>
								</TableCell>
								<TableCell align="center" $smallIcon></TableCell>
							</>
						) : null}
						<TableCell align="center">
							{['beforeCalculation', 'onEdit'].includes(status) ? (
								<OutlinedInput
									error={
										isError &&
										parseFloat(row.grossRetail?.toString() || '') <
											parseFloat(row.retailAmount?.toString() || '')
									}
									isCurrency={true}
									value={formatInputNumberValue(row.retailAmount || '')}
									handleInputNumberChange={formattedValue =>
										handleChangeAmounts(formattedValue, row.serviceId, 'retailAmount')
									}
								/>
							) : (
								<Typography variant="value1">
									{addCurrency(row.retailAmount)}
								</Typography>
							)}
						</TableCell>
						<TableCell align="center" $smallIcon></TableCell>
						<TableCell align="center">
							<Typography variant="value1">
								{addCurrency(row.contractualAdjustmentAmount)}
							</Typography>
						</TableCell>
						<TableCell align="center" $smallIcon>
							<Typography variant="value1" style={{ color: palette.grey2.main }}>
								{addCurrency(row.discountedAmount)}
							</Typography>
						</TableCell>
						<TableCell align="center">
							<Typography variant="value1">
								{addCurrency(row.memberPayAmount)}
							</Typography>
						</TableCell>
						<TableCell align="center" $smallIcon></TableCell>
						<TableCell align="center">
							<Typography variant="value1">
								{addCurrency(row.allowablePlanPay)}
							</Typography>
						</TableCell>
						{editMode &&
						tempAmounts.components.filter(el => isOtherComponent(el)).length > 0 ? (
							<TableCell
								align="center"
								sx={{
									backgroundColor: theme => theme.palette.grey6.main,
									position: 'sticky',
									right: 0,
									width: 44,
								}}
							></TableCell>
						) : null}
					</TableRow>
				))}
		</>
	);
};

const serviceIdToLable = (serviceId: number) =>
	`newClaim.priceEstimate.${
		[undefined, 'lenses', 'frame', 'exam', undefined, 'contacts'][serviceId]
	}`;

export default StandardItemSection;
