import { FC, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Box, Stack, Typography } from 'styles';

import { ReactComponent as ChevronDownIcon } from 'assett/icons/arrow_down_icon.svg';
import { ReactComponent as ChevronUpIcon } from 'assett/icons/arrow_up_icon.svg';
import { handleChange, setSortingValues } from 'feature/form/formSlice';
import { changePage } from 'feature/payments/paymentSlice';
import { useDebounce } from 'hooks/debouncer.hook';
import { AppDispatch, RootState } from 'store/store';
import TextField from 'styles/textfield/TextFieldLogin';
import { useTranslate } from 'stylesHooks';
import { sortingDirections } from 'types/Form';
import { TRANSLATION_KEYS } from 'utils/constants';
import { camelcase } from 'utils/utils';

interface Props {
	name: string;
	children?: React.ReactNode;
	widthBox: number | string;
	widthInput?: number;
	noMargins?: boolean;
	primarySorting?: string;
}

const HeaderCellWithSorting: FC<Props> = props => {
	const { t: tClientLabels } = useTranslate(TRANSLATION_KEYS.CLIENT_LABELS);
	const dispatch = useDispatch<AppDispatch>();
	const inputForm = useSelector((store: RootState) => store.form);
	const currentInputName = `input${camelcase(props.name)}`;

	const handleSorting = (name: string, value: string) => {
		dispatch(changePage(1)); // set the

		if (props.primarySorting) {
			dispatch(
				setSortingValues({
					name: name + `,${props.primarySorting}`,
					value: value + ',DESC',
				}),
			);
		} else {
			dispatch(setSortingValues({ name, value }));
		}
	};

	const getValue = () => {
		switch (props.name) {
			case 'paymentRunId':
				return inputForm.inputPaymentRunId;
			case 'totalAmount':
				return inputForm.inputTotalAmount;
			case 'externalClaimId':
				return inputForm.inputExternalClaimId;
			case 'firstName':
				return inputForm.inputFirstName;
			case 'lastName':
				return inputForm.inputLastName;
			default:
				return '';
		}
	};

	const [filterValue, setFilterValue] = useState<string | undefined>(
		getValue() as string,
	);
	const filterValueDeb = useDebounce(filterValue || '');

	useEffect(() => {
		if (!props.children)
			dispatch(
				handleChange<any>({
					name: currentInputName,
					value: filterValueDeb,
				}),
			);
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [currentInputName, dispatch, filterValueDeb, props.children]);

	return (
		<Box sx={{ width: props.widthBox }}>
			{inputForm.search ? (
				!props.children ? (
					<TextField
						label={tClientLabels(`forms.header.${props.name}`)}
						sx={{ width: props.widthInput }}
						name={currentInputName}
						onChange={evt => setFilterValue(evt.target.value)}
						value={filterValue || ''}
					/>
				) : (
					props.children
				)
			) : (
				<Stack direction={'row'} alignItems={'center'}>
					<Typography
						variant="bodyLink"
						sx={{ ml: props.noMargins ? 0 : 1.7, cursor: 'pointer' }}
						onClick={() =>
							handleSorting(
								props.name,
								inputForm.sortingFields.split(',')[0] === props.name &&
									inputForm.sortingDirections.split(',')[0] === sortingDirections.ASC
									? sortingDirections.DESC
									: sortingDirections.ASC,
							)
						}
					>
						{tClientLabels(`forms.header.${props.name}`)}
					</Typography>

					{inputForm.sortingFields.split(',')[0] === props.name ? (
						inputForm.sortingDirections.split(',')[0] === sortingDirections.ASC ? (
							<ChevronUpIcon />
						) : (
							<ChevronDownIcon />
						)
					) : null}
				</Stack>
			)}
		</Box>
	);
};

export default HeaderCellWithSorting;
