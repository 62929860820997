import { resetClaims } from 'feature/claims/claimsSlice';
import {
	handleChange,
	resetFiltersClaims,
	resetFiltersPayments,
	setSearch,
} from 'feature/form/formSlice';
import { resetPayments } from 'feature/payments/paymentsSlice';
import { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useLocation } from 'react-router-dom';
import { AppDispatch, RootState } from 'store/store';
import { claimStatusIds } from 'types/Claims';
import { RESET_SELECTED_ROUTES } from 'utils/constants';

export const useGetClaimStatusProperty = () => {
	const { claimsStatuses } = useSelector((store: RootState) => store.form);

	return (statusId: claimStatusIds) =>
		claimsStatuses.find(el => el.id === statusId) || {
			id: 0,
			name: '',
			label: '',
			groupCode: '',
			groupLabel: '',
		};
};

export const useResetPage = () => {
	const dispatch = useDispatch<AppDispatch>();
	const { pathname: currentPath } = useLocation();

	const sectionPage = currentPath.split('/')[1];

	useEffect(() => {
		const isViewClaims = currentPath.startsWith(
			RESET_SELECTED_ROUTES.VIEW_CLAIMS,
		);
		const isViewPaymentHistory = currentPath.startsWith(
			RESET_SELECTED_ROUTES.VIEW_PAYMENT_HISTORY,
		);

		if (isViewClaims) {
			dispatch(resetPayments());
			dispatch(resetFiltersPayments());
			return;
		}

		if (isViewPaymentHistory) {
			dispatch(resetClaims());
			dispatch(resetFiltersClaims());
			return;
		}

		dispatch(resetFiltersPayments());
		dispatch(resetFiltersClaims());
		dispatch(resetPayments());
		dispatch(resetClaims());
	}, [currentPath, dispatch]);

	useEffect(() => {
		if (sectionPage !== 'check-and-submit') {
			dispatch(
				handleChange<any>({
					name: 'dateOfService',
					value: null,
				}),
			);
		}

		dispatch(setSearch(false));
	}, [dispatch, sectionPage]);
};
