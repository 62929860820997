import useDevice from 'hooks/DesktopDevice.hook';
import { Stack, Typography } from 'styles';
import { useTheme, useTranslate } from 'stylesHooks';
import { TRANSLATION_KEYS } from 'utils/constants';

const PaymentClaimsHeader = () => {
	const { t: tClientLabels } = useTranslate(TRANSLATION_KEYS.CLIENT_LABELS);
	const theme = useTheme();
	const { isDesktop } = useDevice();

	const headerTypographySX = { width: isDesktop ? '300px' : '100%' };

	return (
		<Stack
			direction={'row'}
			position="sticky"
			top={0}
			zIndex={10}
			width={'100%'}
			sx={{
				px: !isDesktop ? 0.8 : 1.25,
				backgroundColor: theme.palette.grey6.main,
			}}
		>
			<Stack
				flexGrow={1}
				direction={'row'}
				sx={{ height: 76 }}
				alignItems={'center'}
			>
				<Typography variant="bodyLink" sx={headerTypographySX}>
					{tClientLabels('forms.header.claimId')}
				</Typography>
				<Typography variant="bodyLink" sx={headerTypographySX}>
					{tClientLabels('forms.header.invoiceNumber')}
				</Typography>
				<Typography variant="bodyLink" sx={headerTypographySX}>
					{tClientLabels('forms.header.totalAmountPaid')}
				</Typography>
				<Typography variant="bodyLink" sx={headerTypographySX}>
					{tClientLabels('forms.header.assessmentFee')}
				</Typography>
				<Typography variant="bodyLink" sx={headerTypographySX}>
					{tClientLabels('forms.header.assessmentFeeInvoice')}
				</Typography>
			</Stack>
		</Stack>
	);
};

export default PaymentClaimsHeader;
