import {
	getClaimDetails,
	getDoctorTypes,
	onChangeClaim,
	resetClaim,
	setSelectedPage,
	setStatusCalculation,
} from 'feature/claims/claimSlice';
import { useDispatch, useSelector } from 'react-redux';
import { useLocation, useNavigate } from 'react-router-dom';
import { AppDispatch, RootState } from 'store/store';
import { BoxOverflow, Button, Stack, StepperUI, Typography } from 'styles';

import { getMemberDetails } from 'feature/members/memberSlice';
import { resetUpload } from 'feature/upload/uploadSlice';
import { useQuery } from 'hooks/useQuery.hook';
import { useEffect } from 'react';
import { useTranslate } from 'stylesHooks';
import { IClaim } from 'types/Claim';
import { TRANSLATION_KEYS } from 'utils/constants';
import { getShortDate } from 'utils/utils';
import Page0 from './Page0.component';
import Page1 from './Page1.component';
import Page2 from './Page2.component';

const NewClaimForm = () => {
	const navigate = useNavigate();
	const location = useLocation();
	const query = useQuery();
	const claimId = query.get('claimId');
	const { t: tClientLabels } = useTranslate(TRANSLATION_KEYS.CLIENT_LABELS);
	const dispatch = useDispatch<AppDispatch>();

	const { member } = useSelector((store: RootState) => store.member);
	useEffect(() => {
		if (!member && !claimId) {
			navigate('/check-and-submit');
		}
	}, [claimId, member, navigate]);

	// useEffect(() => {
	// 	dispatch(getDocuments({ showloader: true }));
	// }, [dispatch]);

	const {
		claimData: formData,
		triggerRefresh,
		redirect,
		statusCalculation,
	} = useSelector((state: RootState) => state.claim);

	useEffect(() => {
		if (member?.subscriptionId !== formData.beneficiarySubscriptionId) {
			dispatch(
				getMemberDetails({
					subscriptionId: formData.beneficiarySubscriptionId,
					dateOfService: formData.claimDate,
					callback: () => {},
				}),
			);
		}
	}, [
		dispatch,
		formData.beneficiarySubscriptionId,
		formData.claimDate,
		member?.subscriptionId,
	]);

	const selectedPage = formData.step;

	const setFormData = (changeFunction: (prevValue: IClaim) => IClaim) => {
		dispatch(onChangeClaim(changeFunction));
	};

	const submitPage = () => {
		//we no longer save at each step
		// dispatch(saveClaim({ switchPage: true, redirect: false }));
		setFormData(form => ({ ...form, step: form.step + 1 }));
	};

	//lets save claimId on url as soon as its created
	useEffect(() => {
		if (formData.claimId && !claimId) {
			navigate(`${location.pathname}?claimId=${formData.claimId}`);
		}
	}, [claimId, formData.claimId, location.pathname, navigate]);

	useEffect(() => {
		if (triggerRefresh && claimId) {
			dispatch(getClaimDetails(claimId));
		}
	}, [claimId, dispatch, triggerRefresh]);

	useEffect(() => {
		dispatch(getDoctorTypes(''));
	}, [dispatch]);

	//redirect at the end of evrything
	useEffect(() => {
		if (redirect) {
			navigate(`/view-claims`);
		}
		return () => {
			dispatch(resetClaim());
			dispatch(resetUpload());
		};
	}, [dispatch, navigate, redirect]);

	return (
		<>
			<Stack sx={{ height: '98%' }}>
				<Stack direction="row" pt="17px" columnGap="30px">
					<Stack direction="row" gap="0.2em">
						<Typography variant="value3">
							{tClientLabels('newClaim.subheader.patientName')}
						</Typography>
						<Typography variant="value1">{formData.memberFullName}</Typography>
					</Stack>
					<Stack direction="row" gap="0.2em">
						<Typography variant="value3">
							{tClientLabels('newClaim.subheader.dateOfService')}
						</Typography>
						<Typography variant="value1">
							{getShortDate(formData.claimDate)}
						</Typography>
					</Stack>
				</Stack>
				<StepperUI
					sx={{ mb: '40px' }}
					steps={['typeOfService', 'prescription', 'priceEstimate'].map(
						(pageName, pageIndex) => ({
							title: tClientLabels(`newClaim.selectedPage.steps.${pageName}`),
							page: pageIndex,
						}),
					)}
					setSelectedPage={pageNumber => {
						dispatch(setSelectedPage(pageNumber));
					}}
					selectedPage={selectedPage || 0}
				></StepperUI>
				{
					[
						<BoxOverflow variant="y" key="1">
							<Page0
								formData={formData}
								setFormData={setFormData}
								submitPage={submitPage}
							/>
						</BoxOverflow>,
						<BoxOverflow variant="y" key="2" id="page1-scroll">
							{/* <Box style={{ overflowY: 'auto', overflowX: 'hidden' }}> */}
							<Page1
								formData={formData}
								setFormData={setFormData}
								submitPage={submitPage}
							/>
						</BoxOverflow>,
						<>
							<Stack direction="row" mb={'12px'} pr={'35px'} gap="20px">
								<Typography variant="title6">
									{tClientLabels('newClaim.selectedPage.sectionTitile.priceEstimate')}
								</Typography>
								{['afterCalculation'].includes(statusCalculation) ? (
									<Button
										variant="linkDark"
										onClick={() => {
											dispatch(setStatusCalculation('onEdit'));
										}}
										sx={{ minWidth: 'auto' }}
									>
										{tClientLabels('newClaim.priceEstimate.edit')}
									</Button>
								) : null}
							</Stack>
							<BoxOverflow variant="y" key="3">
								<Page2 formData={formData} setFormData={setFormData} />
							</BoxOverflow>
						</>,
					][selectedPage]
				}
			</Stack>
		</>
	);
};

export default NewClaimForm;
