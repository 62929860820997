export const TRANSLATION_KEYS = {
	SEARCH_LOCATION: 'searchLocation',
	SEARCH_LOCATION_FILTERS: 'searchLocation.filters',
	PROVIDER_DETAILS: 'providerDetails',
	DIALOG: 'dialog',
	COMMONS: 'commons',
	CLAIMS: 'claims',
	OONCLAIMS: 'oonClaims',
	SPECIAL_OFFERS: 'specialOffers',
	PRESCRIPTIONS: 'prescriptions',
	MY_BENEFITS: 'myBenefits',
	MY_PROFILE: 'myProfile',
	DB_LABELS: 'dbLabels',
	ERRORS: 'errorsMessages',
	CLIENT_LABELS: 'clientLabels',
	CLIENT_LABELS_INDEX: 'clientLabels.Index',
	UPLOAD_FILE_DIALOG: 'uploadFileDialog',
	INDEX: 'Index',
	CONTACT_US: 'contactUs',
	KNOW_BEFORE_YOU_GO: 'knowBeforeYouGo',
	SERVICE: 'service',
};

export const SERVICES_SHORT_TRANSLATION_KEY = 'short';
// for the moment we have special needs only on 'others'
export enum SERVICES_GROUPS {
	OTHERS = 'others',
}

export enum RESET_SELECTED_ROUTES {
	VIEW_CLAIMS = '/view-claims',
	VIEW_PAYMENT_HISTORY = '/view-payment-history',
}

export const TEMP_SERVICES = [
	{
		code: 'exam',
		label: 'service.exam',
		group: {
			code: '',
			label: '',
		},
	},
	{
		code: 'lens',
		label: 'service.lens',
		group: {
			code: '',
			label: '',
		},
	},
	{
		code: 'frame',
		label: 'service.frame',
		group: {
			code: '',
			label: '',
		},
	},
	{
		code: 'contacts',
		label: 'service.contacts',
		group: {
			code: '',
			label: '',
		},
	},
	{
		code: 'fitting',
		label: 'service.fitting',
		group: {
			code: 'others',
			label: 'service_group.others',
		},
	},
	{
		code: 'warranty',
		label: 'service.warranty',
		group: {
			code: 'others',
			label: 'service_group.others',
		},
	},
	{
		code: 'second-pair',
		label: 'service.second-pair',
		group: {
			code: 'others',
			label: 'service_group.others',
		},
	},
	{
		code: 'cleaning-kit',
		label: 'service.cleaning-kit',
		group: {
			code: 'others',
			label: 'service_group.others',
		},
	},
	{
		code: 'generic',
		label: 'service.generic',
		group: {
			code: 'others',
			label: 'service_group.others',
		},
	},
	{
		code: 'vat',
		label: 'service.vat',
		group: {
			code: 'others',
			label: 'service_group.others',
		},
	},
];
